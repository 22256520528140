















































import { Component, Vue } from 'vue-property-decorator'
import * as RecipeAPI from '@/api/recipe'
import { DescriptionStep, IngredientEntry } from '@/api/recipe'
/* eslint-disable @typescript-eslint/camelcase */

@Component({
  name: 'Create',
  components: {}
})
export default class extends Vue {
  image = null
  name = ''
  e6 = 1
  timeSlider = 3
  ticksLabels = ['30m', '1h', '3h', '∞']
  portions = 2
  meat = false
  vegetarian = false
  vegan = false
  ingredients: any[] = []
  descriptionSteps: string[] = []
  standardPortions = 2
  currentPortions = 2
  async created() {
    const { data } = await RecipeAPI.get(this.$route.params.id)
    if (data != null) {
      this.image = data.image
      this.name = data.recipe_translations[0].title
      this.ingredients = data.recipe_to_ingredient
      this.descriptionSteps = data.recipe_translations[0].description.split('҂')
      this.standardPortions = data.portions
      this.currentPortions = data.portions
    }
  }
  reducePortions() {
    if (this.currentPortions > 1) {
      this.currentPortions--
    }
  }
  increasePortions() {
    this.currentPortions++
  }
}
